import { Switch, Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from 'react-query';

//HOOKS
import AppContext from './utils/context/AppContext';
import useInitialState from './hooks/useInitialState';

// VIEWS and Components
import Layout from './components/Layout/Layout';
import ActiveOrders from './pages/ActiveOrders';
import HistoryOrders from './pages/HistoryOrders';
import Loading from './components/Loading/Loading';
import CanceledOrders from './pages/CanceledOrders';

const queryClientActiveOrders = new QueryClient();
const queryClientCompletedOrders = new QueryClient();
const queryClientCanceledOrders = new QueryClient();

function App() {
  const initialState = useInitialState();

  return (
    <AppContext.Provider value={initialState}>
      <Layout>
        <Switch>
          <Route exact path='/'>
            <QueryClientProvider client={queryClientActiveOrders}>
              <ActiveOrders />
            </QueryClientProvider>
          </Route>
          <Route path='/history'>
            <QueryClientProvider client={queryClientCompletedOrders}>
              <HistoryOrders />
            </QueryClientProvider>
          </Route>
          <Route path='/canceled'>
            <QueryClientProvider client={queryClientCanceledOrders}>
              <CanceledOrders />
            </QueryClientProvider>
          </Route>
        </Switch>
      </Layout>
    </AppContext.Provider>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading />,
});
