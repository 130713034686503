import React, { useState, useMemo, useContext } from 'react';

import axios from 'axios';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faListAlt,
  faHistory,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

// APP CONTEXT
import AppContext from '../utils/context/AppContext';

// SCSS
import './_ordersPages.scss';
import DialogSimple from '../components/DialogSimple/DialogSimple';

const atheneaUrl = process.env.REACT_APP_ATHENEA_URL;

const HistoryOrders = () => {
  const { state } = useContext(AppContext);
  const { user } = state;

  const fetchDeliveries = async () => {
    const res = await axios.get(
      `${atheneaUrl}/v1/requesters/${
        user['https://kiwibot:us:auth0:com/metadata'].requester_id ??
        '128e4379-98d0-4235-b5d9-2481f2bbe9f5'
      }/deliveries?activity=delivered&key=${
        process.env.REACT_APP_ATHENEA_API_KEY
      }`
    );
    return res.data;
  };

  const { status, data } = useQuery('historyDeliveries', fetchDeliveries, {
    refetchInterval: 1000,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    cacheTime: 0,
    keepPreviousData: false,
    enabled: user ? true : false,
  });

  const [externalIdAndClientNameFilter, setExternalIdAndClientNameFilter] =
    useState('');

  const completedOrders = useMemo(() => {
    if (!data) return [];
    if (externalIdAndClientNameFilter.length > 0) {
      return data.deliveries.filter((delivery) =>
        delivery.dropoff.name
          ? delivery.dropoff.name
              .toLowerCase()
              .includes(externalIdAndClientNameFilter.toLowerCase())
          : null || delivery.external_id
          ? delivery.external_id
              .toLowerCase()
              .includes(externalIdAndClientNameFilter.toLowerCase())
          : null
      );
    }
    return data.deliveries ?? [];
  }, [data, externalIdAndClientNameFilter]);

  return (
    <>
      <h2 className='typeorders_title'>Completed Orders</h2>
      <div className='orders_container'>
        <div className='group-nav-top'>
          <nav className='orders_container__nav'>
            <ul>
              <li>
                <Link to='/'>
                  <FontAwesomeIcon
                    style={{ marginRight: '3px' }}
                    icon={faListAlt}
                    size='1x'
                    fixedWidth
                  />
                  Active Orders
                </Link>
              </li>
              <li>
                <Link className='active' to='/history'>
                  <FontAwesomeIcon
                    style={{ marginRight: '3px' }}
                    icon={faHistory}
                    size='1x'
                    fixedWidth
                  />
                  Completed Orders
                </Link>
              </li>
              <li>
                <Link to='/canceled'>
                  <FontAwesomeIcon
                    style={{ marginRight: '3px' }}
                    icon={faTimesCircle}
                    size='1x'
                    fixedWidth
                  />
                  Canceled Orders
                </Link>
              </li>
            </ul>
          </nav>
          <form
            className='orders_container__filters'
            onSubmit={(e) => e.preventDefault()}
            noValidate>
            <input
              className='filter-input'
              type='text'
              id='filter-externalid-clientname'
              placeholder='Filter by external id or client id'
              onChange={(e) => {
                setExternalIdAndClientNameFilter(e.target.value);
              }}
            />
          </form>
        </div>
        <table className='orders_container__table'>
          <thead>
            <tr className='table-header'>
              <th>ID</th>
              <th>Estimated at pickup</th>
              <th>Estimated delivery time</th>
              <th>Client name</th>
              <th>Kiwibot</th>
              <th>Status</th>
              <th>Details Order</th>
            </tr>
          </thead>
          <tbody>
            {status === 'success' && (
              <>
                {completedOrders.map((delivery) => (
                  <tr className='table-content' key={delivery.id}>
                    <td>{delivery.external_id ?? 'N/A'}</td>
                    <td>
                      {new Date(delivery.estimated_pickup_at).toLocaleString()}
                    </td>
                    <td>
                      {new Date(
                        delivery.estimated_delivery_at
                      ).toLocaleString()}
                    </td>
                    <td>{delivery.dropoff.name ?? 'N/A'}</td>
                    <td>
                      {delivery.worker_id
                        ? delivery.worker_id
                        : 'No available yet'}
                    </td>
                    <td>{delivery.status}</td>
                    <td>
                      <DialogSimple
                        dialogTitle='Details Order'
                        buttonText='View details'
                        delivery={delivery}
                      />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
    // <div>
    //   <Typography variant='h4' component='h2'>
    //     Completed orders
    //   </Typography>
    //   <form className={classes.filterContainer} noValidate>
    //     <TextField
    //       className={classes.textInputFilter}
    //       id='filter-externalid-clientname'
    //       label='Filter by external id or client name'
    //       variant='outlined'
    //       size='small'
    //       defaultValue={externalIdAndClientNameFilter}
    //       onChange={(e) => {
    //         setExternalIdAndClientNameFilter(e.target.value);
    //       }}
    //     />
    //   </form>

    //   <TableContainer component={Paper} className={classes.tableContainer}>
    //     <Table className={classes.table} aria-label='simple table'>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell align='left'>ID</TableCell>
    //           <TableCell align='left'>Estimated at store</TableCell>
    //           <TableCell align='left'>Estimated delivery time</TableCell>
    //           <TableCell align='left'>Client Name</TableCell>
    //           <TableCell align='left'></TableCell>
    //           <TableCell align='left'>Kiwibot</TableCell>
    //           <TableCell align='left'>Status</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {status === 'success' && (
    //           <>
    //             {completedOrders.map((delivery) => (
    //               <TableRow key={delivery.id}>
    //                 <TableCell align='left'>
    //                   {delivery.external_id ?? 'N/A'}
    //                 </TableCell>
    //                 <TableCell align='left'>
    //                   {new Date(delivery.estimated_pickup_at).toLocaleString()}
    //                 </TableCell>
    //                 <TableCell align='left'>
    //                   {new Date(
    //                     delivery.estimated_delivery_at
    //                   ).toLocaleString()}
    //                 </TableCell>
    //                 <TableCell align='left'>
    //                   {delivery.dropoff.name ?? 'N/A'}
    //                 </TableCell>
    //                 <TableCell align='left'>
    //                   <DialogWithoutActions
    //                     buttonText='View Details Order'
    //                     dialogTitle='Details Order'>
    //                     <Typography
    //                       style={{ display: 'block', marginBottom: '20px' }}
    //                       component={'span'}
    //                       variant={'body2'}>
    //                       <Typography
    //                         component={'span'}
    //                         variant={'body2'}
    //                         style={{ display: 'block', fontWeight: 'bold' }}>
    //                         description:{' '}
    //                       </Typography>
    //                       {delivery.manifest.description ?? 'N/A'}
    //                     </Typography>
    //                     {delivery.manifest.items.length > 0 ? (
    //                       <>
    //                         {delivery.manifest.items.map((item) => (
    //                           <li key={item.name + '-3742893'}>
    //                             {item.name} x{item.quantity}
    //                           </li>
    //                         ))}
    //                       </>
    //                     ) : (
    //                       <Typography
    //                         style={{ display: 'block', fontWeight: 'bold' }}
    //                         component={'span'}
    //                         variant={'body2'}>
    //                         No items
    //                       </Typography>
    //                     )}
    //                   </DialogWithoutActions>
    //                 </TableCell>
    //                 <TableCell align='left'>
    //                   <Typography
    //                     component={'span'}
    //                     variant={'body2'}
    //                     style={{ fontWeight: 'bold' }}>
    //                     {delivery.worker_id
    //                       ? delivery.worker_id
    //                       : 'No available yet'}
    //                   </Typography>
    //                 </TableCell>
    //                 <TableCell align='left'>{delivery.status}</TableCell>
    //               </TableRow>
    //             ))}
    //           </>
    //         )}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    // </div>
  );
};

export default HistoryOrders;
