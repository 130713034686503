import Pusher from 'pusher-js';

let instance = null;

class PusherHandler {
  _connectPusher(channel) {
    const socket = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true,
    });
    this.channel = socket.subscribe(channel);
    socket.connection.bind('connected', () =>
      // eslint-disable-next-line no-console
      console.log('Connected to pusher')
    );
    socket.connection.bind('error', (err) =>
      // eslint-disable-next-line no-console
      console.log("Can't connect to pusher", err)
    );
  }

  bindEvent(event, callback) {
    this.channel.bind(event, callback);
  }

  constructor(channel) {
    if (!instance) {
      instance = this;
      this._connectPusher(channel);
    }
    return instance;
  }
}

export default PusherHandler;
